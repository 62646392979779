.Reasons {
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}

/* left section styles */
.left-section {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-section>img {
    object-fit: cover;
}
.left-section>:nth-child(1) {
    width: 12rem;
    grid-row: 1/3;
    height: 29rem;
}
.left-section>:nth-child(2) {
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}
.left-section>:nth-child(3) {
    width: 14rem;
    height: 12rem;
    grid-row: 2;
    grid-column: 2/3;
}
.left-section>:nth-child(4) {
    width: 10rem;
    grid-row: 2;
    grid-column: 3/4;
    height: 12rem;
}

/* right section styles */
.right-section {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
}
.right-section>span {
    font-weight: bold;
    color: var(--orange);
}
.right-section>div {
    display: flex;
    gap: 1rem;
    color: var(--white);
    font-size: 3rem;
    font-weight: bold;
}
.right-section-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.right-section-details>div {
    display: flex;
    gap: 1rem;
    font-size: 1rem;
}
.right-section-details>div>img {
    width: 1.8rem;
    height: 1.8rem;
    filter: invert(65%) sepia(95%) saturate(459%) hue-rotate(305deg) brightness(100%) contrast(81%);
}
.right-section>:nth-child(4) {
    color: var(--gray);
    font-weight: normal;
}
.partners {
    display: flex;
    gap: 1rem;
}
.partners>img {
    width: 5rem;
    height: 2rem;
}

/* mobile view */
@media screen and (max-width: 768px) {
    .Reasons {
        flex-direction: column;
        margin-top: -3rem;
    }
    .right-section-header {
        flex-direction: column;
        font-size: xx-large;
    }
    .right-section-header>span {
        font-size: xx-large;
    }
    .left-section {
        grid-auto-rows: auto;
        overflow: hidden;
        gap: 0.5rem;
    }
    .left-section>:nth-child(1) {
        width: 5rem;
        height: 17rem;
    }
    .left-section>:nth-child(2) {
        width: 10rem;
        height: 10rem;
    }
    .left-section>:nth-child(3) {
        width: 5rem;
        height: 6rem;
    }
    .left-section>:nth-child(4) {
        width: 5rem;
        height: 6rem;
    }
    .partners>img {
        width: 4.5rem;
        height: 2rem;
    }
}