.plans-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-top: -5rem;
    padding: 0 2rem;
    position: relative;
}
.plans-header {
    display: flex;
    gap: 1rem;
    justify-content: center;
    text-transform: uppercase;
    color: var(--white);
    font-size: 3rem;
    font-style: italic;
    font-weight: bold;
}
.plan-categories {
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;
}
.plan {
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    padding: 1.5rem;
    gap: 2rem;
    color: var(--white);
    width: 15rem;
}
.plan:nth-child(2) {
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>svg {
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3) {
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5) {
    font-size: 0.8rem;
}
.plan-categories>:nth-child(2)>svg {
    fill: var(--white);
}
.plan-categories>:nth-child(2)>button {
    color: var(--orange);
}
.features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature>img {
    width: 1rem;
}

.see-more {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.see-more>a {
    text-decoration: none;
    color: #ffffff;
}
.see-more>a>img {
    width: 1rem;
}
.plan-blur-left {
    width: 32rem;
    height: 23rem;
    left: 0;
    top: 6rem;
}
.plan-blur-right {
    width: 32rem;
    height: 23rem;
    right: 0;
    top: 10rem;
}

/* mobile view */
@media screen and (max-width: 768px) {
    .plans-container {
        margin-top: -2.8rem;
    }
    .plans-header {
        flex-direction: column;
        gap: 0.5rem;
        font-size: x-large;
        justify-content: center;
        align-items: center;
    }
    .plan-categories {
        flex-direction: column;
        gap: 1.5rem;
        margin-top: -2rem;
        margin-bottom: -2rem;
    }
    .plan:nth-child(2) {
        transform: none;
    }
    .plan-blur-left {
        width: 22rem;
        height: 20rem;
    }
    .plan-blur-right {
        width: 22rem;
        height: 20rem;
    }
}