.join-container {
    display: flex;
    gap: 10rem;
    padding: 0 2rem;
}
/* left side */
.left-join {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    position: relative;
    text-transform: uppercase;
    color: var(--white);
    font-weight: bold;
    font-size: 3rem;
}
.left-join>hr {
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
}
.left-join>div {
    display: flex;
    gap: 1rem;
}

/* right side */
.right-join {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container {
    display: flex;
    gap: 3rem;
    background-color: var(--caloryCard);
    padding: 1rem 2rem;
}
.email-container>input {
    font-size: 1rem;
    color: var(--white);
    background-color: transparent;
    border: none;
    outline: none;
}
::placeholder {
    color: var(--lightgray);
}
.join-btn {
    background-color: var(--orange);
    color: var(--white);
}

/* mobile view */
@media screen and (max-width: 768px) {
    .join-container {
        flex-direction: column;
        gap: 1rem;
        margin-top: -2rem;
    }
    .left-join {
        flex-direction: column;
        font-size: 1.35rem;
    }
    .right-join {
        padding: 1rem 2rem;
        margin-bottom: -2.5rem;
    }
}